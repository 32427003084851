<template>
  <div class="p-3">
    <div class="p-card flex justify-content-center">
      <div class="p-card-body" style="width: 80vw">
        <h2>Invite User</h2>

        <div class="p-field mb-4">
          <label for="firstNameInput" class="block text-900 font-medium mb-2"
            >First Name</label
          >
          <InputText
            type="text"
            id="firstNameInput"
            class="p-inputtext p-component w-full p-3"
            :class="
              validationErrors && validationErrors.first_name ? 'p-invalid' : ''
            "
            v-model="form.first_name"
          />
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.first_name"
          >
            {{ validationErrors.first_name[0] }}
          </small>
        </div>

        <div class="p-field mb-4">
          <label for="emailInput" class="block text-900 font-medium mb-2"
            >Email</label
          >
          <InputText
            type="email"
            id="emailInput"
            class="p-inputtext p-component w-full p-3"
            :class="
              validationErrors && validationErrors.email ? 'p-invalid' : ''
            "
            v-model="form.email"
          />
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.email"
          >
            {{ validationErrors.email[0] }}
          </small>
        </div>

        <div class="p-field mb-4">
          <Checkbox
            id="adminCheckbox"
            v-model="form.is_admin"
            :binary="true"
            :class="
              validationErrors && validationErrors.is_admin ? 'p-invalid' : ''
            "
          />
          <label for="adminCheckbox" class="mx-3">Is Admin</label>
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.is_admin"
          >
            {{ validationErrors.is_admin[0] }}
          </small>
        </div>

        <div class="p-field mb-4">
          <Checkbox
            id="inviteUsersCheckbox"
            v-model="form.invite_users"
            :binary="true"
            :class="
              validationErrors && validationErrors.invite_users
                ? 'p-invalid'
                : ''
            "
          />
          <label for="inviteUsersCheckbox" class="mx-3">Can Invite Users</label>
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.invite_users"
          >
            {{ validationErrors.invite_users[0] }}
          </small>
        </div>

        <div class="p-field mb-4">
          <Checkbox
            id="switchClientsCheckbox"
            v-model="form.switch_clients"
            :binary="true"
            :class="
              validationErrors && validationErrors.switch_clients
                ? 'p-invalid'
                : ''
            "
          />
          <label for="switchClientsCheckbox" class="mx-3"
            >Can Switch Clients</label
          >
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.switch_clients"
          >
            {{ validationErrors.switch_clients[0] }}
          </small>
        </div>

        <Button
          label="CREATE INVITE"
          icon="pi pi-send"
          class="p-button p-component w-full p-3"
          @click.prevent="createInvite"
          :loading="loading"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import UserInvite from "@/models/UserInvite";

export default {
  name: "InviteUsers",
  data() {
    return {
      form: {
        first_name: "",
        email: "",
        is_admin: false,
        switch_clients: false,
        invite_users: false,
      },
      loading: false,
      validationErrors: null,
    };
  },
  methods: {
    createInvite() {
      this.loading = true;
      let i = new UserInvite({
        ...this.form,
      });
      i.save()
        .then(() => {
          // Clear form data
          this.form = {
            first_name: "",
            email: "",
            is_admin: false,
            switch_clients: false,
            invite_users: false,
          };

          // Show toast
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Invite sent.",
            life: 5000,
          });
        })
        .catch(() => {})
        .then(() => (this.loading = false));
    },
  },
};
</script>
